import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Livecoding #12: towards animating 10k+ elements with React",
  "description": "",
  "date": "2016-06-16T08:00:00.000Z",
  "published": "2016-06-16T08:00:00.000Z",
  "image": ""
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><em parentName="p">{`This is a Livecoding Recap - an almost-weekly post about interesting things discovered while livecoding. It is shorter than 500 words. There are pictures. You can follow my channel, `}<a parentName="em" {...{
          "href": "https://www.youtube.com/user/TheSwizec/"
        }}>{`here`}</a>{`. New stuff shows up almost `}<strong parentName="em">{`every Sunday at 2pm PDT`}</strong>{`. There’s live chat ;)`}</em></p>
    <p>{`Welcome to the first Livecoding Recap. This recap is for the 12th live coding. I don’t have an excuse for not recapping before now. It just took me this long to realize `}<em parentName="p">{`“These are fun, and I learn a lot. I should turn them into short blogposts.”`}</em></p>
    <p>{`This Sunday, I started a new quest: To find the definitive answer to `}<em parentName="p">{`"How do you smoothly animate 10,000 elements in React?”`}</em>{`.`}</p>
    <p>{`Many people have asked me about this recently, and I realized I couldn’t give them a good answer. The best I could come up with was, `}<em parentName="p">{`“Try to render fewer nodes?”`}</em>{`. The issue is that sometimes you really `}<em parentName="p">{`do`}</em>{` need that many nodes because you don’t want to sacrifice fidelity.`}</p>
    <p>{`So I took my old `}<a parentName="p" {...{
        "href": "http://swizec.com/blog/animating-with-react-redux-and-d3/swizec/6775"
      }}>{`particle generator experiment`}</a>{` and started playing around. It doesn’t use a lot of d3, but it does rely heavily on React and Redux.`}</p>
    <p>{`It works like this:`}</p>
    <ul>
      <li parentName="ul">{`React renders `}<inlineCode parentName="li">{`<circle>`}</inlineCode>{` elements in a loop`}</li>
      <li parentName="ul">{`Redux holds an array of `}<inlineCode parentName="li">{`[x, y]`}</inlineCode>{` positions`}</li>
      <li parentName="ul">{`Trigger a `}<inlineCode parentName="li">{`move particles`}</inlineCode>{` action 60-times per second`}</li>
      <li parentName="ul">{`Add new particles if mousedown`}</li>
    </ul>
    <p>{`If you change `}<inlineCode parentName="p">{`N`}</inlineCode>{` particles per loop from 5 to 1,000, all hell breaks loose. Animation grinds to a halt.`}</p>
    <p><img parentName="p" {...{
        "src": "http://swizec.com/blog/wp-content/uploads/2016/06/livecoding-12-1-1024x551.png",
        "alt": "livecoding-12-1"
      }}></img></p>
    <p>{`To get to the bottom of `}<em parentName="p">{`why`}</em>{` this happens, we fired up the profiler. There are two suspects:`}</p>
    <ol>
      <li parentName="ol">{`Creating new instances of the particles array 60-times per second`}</li>
      <li parentName="ol">{`Rendering those DOM nodes`}</li>
    </ol>
    <p>{`The profiler wasn’t happy with us:`}</p>
    <p><img parentName="p" {...{
        "src": "http://swizec.com/blog/wp-content/uploads/2016/06/livecoding-12-2-1024x521.png",
        "alt": "livecoding-12-2"
      }}></img></p>
    <p>{`It seems that “scripting” is the culprit, not rendering. In a 21 second experiment, 18 seconds were spent “scripting”, and less than 2 were spent rendering. Curious.`}</p>
    <p>{`This implies that copying the array is our big timesink. If this is true, then the fix is simple – use `}<a parentName="p" {...{
        "href": "https://facebook.github.io/immutable-js/"
      }}>{`immutable.js`}</a>{`. Immutable.js promises to be smart about implementing proper immutable data structures efficiently.`}</p>
    <p>{`We turned off rendering and kept the array copying. Things became silky smooth.`}</p>
    <p>{`Curiouser and curiouser.`}</p>
    <p>{`Looking deeper into the profiler reveals that the problem isn’t data management after all; it’s updating children, either in React or in the DOM.`}</p>
    <p><img parentName="p" {...{
        "src": "http://swizec.com/blog/wp-content/uploads/2016/06/livecoding-12-3-1024x185.png",
        "alt": "livecoding-12-3"
      }}></img></p>
    <p>{`Interesting.`}</p>
    <p>{`The next thing to try is finding the bottleneck in updating children. Is it React, or is it the DOM? We can figure that out by replacing React rendering with raw d3 rendering.`}</p>
    <p>{`This had funny results. Particles didn’t update properly, the animation was glitchy, and there were staleness artifacts galore.`}</p>
    <p>{`Looks like React `}<em parentName="p">{`does`}</em>{` make our lives easier, despite how great d3 is on its own.`}</p>
    <p><img parentName="p" {...{
        "src": "/1f4770cf5bfc4d039df5689a8c1daacc/Thz3F4K.gif",
        "alt": null
      }}></img></p>
    <p>{`But it `}<em parentName="p">{`is`}</em>{` faster.`}</p>
    <p>{`Then we looked at `}<a parentName="p" {...{
        "href": "https://github.com/chenglou/react-motion"
      }}>{`react-motion`}</a>{` and `}<a parentName="p" {...{
        "href": "https://github.com/reactjs/react-art"
      }}>{`react-art`}</a>{`, and we decided that they don’t smell like they can solve our problem. Maybe I just need to invest more time into figuring them out.`}</p>
    <p>{`React-motion did give us a great idea though – use css transformations! The GPU renders those; that `}<em parentName="p">{`has got to be fast`}</em>{`, right?`}</p>
    <p>{`Nope. Not fast.`}</p>
    <p>{`Looks like we do have to get away from the DOM completely. The most promising library we could find to do that was `}<a parentName="p" {...{
        "href": "https://medium.com/@lavrton/using-react-with-html5-canvas-871d07d8d753#.lrwnvr1z6"
      }}>{`react-koonva`}</a>{`.`}</p>
    <p>{`I’m sure that `}<em parentName="p">{`next time`}</em>{`, we will crack this nut. There `}<em parentName="p">{`will`}</em>{` be 10,000 dancing dots on the screen.`}</p>
    <p>{`It’s gonna be great.`}</p>
    <hr></hr>
    <p>{`PS: the edited and improved versions of these videos are becoming a video course. Readers of the engineer package of `}<a parentName="p" {...{
        "href": "http://swizec.com/reactd3js#packages"
      }}>{`React+d3js ES6`}</a>{` get it for free when it’s ready.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      